<template>
  <div id="app">
  </div>
</template>

<script>
import {ZoomMtg} from '@zoomus/websdk';
import lz from "lz-string";

export default {
  name: 'ZoomClient',
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('zoom')) {
      let data = this.decompress(urlParams.get('zoom').replaceAll(' ', '+'))
      this.sdkKey = data.sdkKey
      this.sdkSecret = data.sdkSecret
      this.meetingNumber = data.meetingNumber
      this.passWord = data.passWord
      this.role = data.role
      this.userEmail = data.userEmail
      this.userName = data.userName
      this.leaveUrl = data.leaveUrl
    }
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.7/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load('en-US');
    ZoomMtg.i18n.reload('en-US');
  },
  mounted() {
    // inMeetingServiceListener for all users
    // ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
    //   console.log("inMeetingServiceListener onUserJoin", data);
    // });
    // ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
    //   console.log('User leave')
    //   console.log(data);
    // });
    // ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
    //   // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
    //   console.log(data);
    // });
    this.joinMeeting()
  },
  data() {
    return {
      sdkKey: '',
      sdkSecret: '',
      meetingNumber: '',
      passWord: '',
      role: 0,
      userEmail: 'test@example.ru',
      userName: 'User Name',
      leaveUrl: '',
      client: null
    }
  },
  methods: {
    decompress(string) {
      return JSON.parse(lz.decompressFromBase64(string))
    },
    joinMeeting() {
      ZoomMtg.generateSDKSignature({
        sdkKey: this.sdkKey,
        sdkSecret: this.sdkSecret,
        meetingNumber: this.meetingNumber,
        role: this.role,
        success: (signature) => {
          ZoomMtg.init({
            leaveUrl: this.leaveUrl,
            isSupportChat: false,
            success: () => {
              ZoomMtg.join({
                signature: signature.result,
                sdkKey: this.sdkKey,
                meetingNumber: this.meetingNumber,
                passWord: this.passWord,
                userName: this.userName,
                userEmail: this.userEmail,
                // success: (success) => {
                //   console.log(success);
                // },
                error: (error) => {
                  console.log(error);
                }
              });
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error)
        }
      })
    },
  }
}
</script>

<style>
@import "https://source.zoom.us/2.9.7/css/bootstrap.css";
@import "https://source.zoom.us/2.9.7/css/react-select.css";
</style>